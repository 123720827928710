import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../Components/layout';
import Hero from '../Components/hero';
import styled from 'styled-components';
import theme from '../utils/theme';

const PrivacyPolicyContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  h5 {
    font-weight: 500;
    color: ${theme.primaryLight};
    font-size: 16px;
  }
  p {
    font-size: 16px;
    max-width: 800px;
  }
  table thead tr,
  table thead th,
  table tbody tr {
    background-color: transparent;
  }
  table td {
    border: none;
  }
  ul {
    margin-bottom: 20px;
    li {
      font-size: 16px;
      line-height: 29px;
      margin-left: 1em;
      :before {
        content: '\u2022';
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        color: ${theme.primaryLight};
      }
    }
  }
`;

class PrivacyPolicy extends React.Component {
  render() {
    let {allMarkdownRemark} = this.props.data;
    return (
      <Layout>
        <PrivacyPolicyContainer>
          <Hero
            content={
              allMarkdownRemark && allMarkdownRemark.edges && allMarkdownRemark.edges.length > 0
                ? allMarkdownRemark.edges.pop().node.html
                : ''
            }
            options={{bgColor: 'none'}}
          />
        </PrivacyPolicyContainer>
      </Layout>
    );
  }
}

export default PrivacyPolicy;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {id: {eq: "privacy"}}}) {
      edges {
        node {
          html
          frontmatter {
            id
          }
        }
      }
    }
  }
`;
